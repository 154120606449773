/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
/*@import 'https://fonts.googleapis.com/icon?family=Material+Icons';*/
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

/* @import "../node_modules/bootstrap/dist/css/bootstrap.min.css"; */
/*styles of app.css*/
@import "../node_modules/bootstrap/dist/css/bootstrap.css";

body{
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}
.autocomplete-suggestions {
    border: 1px solid #999;
    background: #FFF;
    cursor: default;
    overflow: auto;
    -webkit-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
    -moz-box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
    box-shadow: 1px 4px 3px rgba(50, 50, 50, 0.64);
}

.autocomplete-suggestion {
    padding: 10px 10px;
    white-space: nowrap;
    overflow: hidden;
}

.autocomplete-no-suggestion {
    padding: 10px 10px;
}

.autocomplete-selected {
    background: #F0F0F0;
}

.autocomplete-suggestions strong {
    font-weight: bold;
    color: #000;
}

.autocomplete-group {
    padding: 10px 10px;
}

    .autocomplete-group strong {
        font-weight: bold;
        font-size: 16px;
        color: #000;
        display: block;
        border-bottom: 1px solid #000;
    }

.loadinggif {
    background: url('/Content/Images/ajax-loader.gif') no-repeat right center;
    background-color: white;
}

textarea {
    width: 100% !important;
}

tr.spaceUnder > td {
    padding-bottom: 1em;
}

.modal-dialog {
    overflow-y: initial !important;
}

/*.modal-body {
    height: 400px;
    overflow-y: auto;
}*/

#light span {
    border-radius: 50px;
    display: block;
    height: 18px;
    opacity: 1;
    width: 18px;
    margin: 5px;
}


/*! Active states */

#light #Status.At.Risk {
    box-shadow: 0 0 5px red;
    background-color: red;
}

#light #Status.Completed {
    box-shadow: 0 0 5px;
    background-color: blue;
}


#light #Status.Planned {
    box-shadow: 0 0 10px;
    background-color: green;
}

#light #Status.In.Progress {
    box-shadow: 0 0 10px;
    background-color: green;
}

#light #Status.Cancelled {
    box-shadow: 0 0 10px;
    background-color: green;
}

/*issue here*/
#light #Status.cancelledinvalid {
    box-shadow: 0 0 10px;
    background-color: green;
}

#light #Status.Delayed {
    box-shadow: 0 0 10px;
    background-color: #FFC200;
}

#light #Status.Failed.to.Prevent {
    box-shadow: 0 0 10px;
    background-color: green;
}

#light #OverAllStatus.Red {
    box-shadow: 0 0 10px;
    background-color: red;
}

#light #OverAllStatus.Green {
    box-shadow: 0 0 10px;
    background-color: green;
}

#light #OverAllStatus.Yellow {
    box-shadow: 0 0 10px;
    background-color: #FFC200;
}
#light #OverAllStatus.DarkPink {
    box-shadow: 0 0 10px;
    background-color:deeppink;
}

.modal-footer {
    text-align: initial !important;
}


.table-striped {
    width: 100%;
}

.content {
    position: absolute;
    overflow-y: scroll;
}

.button {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

#dyscrollup-btn {
    z-index: 999999;
    position: fixed;
    cursor: pointer;
    display: none;
    bottom: 32px;
}
/*
 * Base structure
 */

/* Move down content because we have a fixed navbar that is 50px tall */
/*body {
    padding-top: 50px;
}*/


/*
 * Global add-ons
 */

.sub-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
    border: 0;
}

/*
 * Sidebar
 */

/* Hide for mobile, show later */
.sidebar {
    display: none;
}

@media (min-width: 768px) {
    .sidebar {
        position: fixed;
        top: 51px;
        bottom: 0;
        left: 0;
        z-index: 1000;
        display: block;
        padding: 20px;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
        background-color: #f5f5f5;
        border-right: 1px solid #eee;
    }
}

/* Sidebar navigation */
.nav-sidebar {
    margin-right: -21px; /* 20px padding + 1px border */
    margin-bottom: 20px;
    margin-left: -20px;
}

  

    .nav-sidebar > li > a {
        padding-right: 20px;
        padding-left: 20px;
        color: #eee !important;
    }

        .nav-sidebar > li > a:hover {
            color: #9d9d9d !important;
        }

    .nav-sidebar > .active > a,
    .nav-sidebar > .active > a:hover,
    .nav-sidebar > .active > a:focus {
        color: #fff;
        background-color: #428bca;
    }


/*
 * Main content
 */

/*.main {
    padding: 20px;
   
}*/

@media (min-width: 768px) {
    .main {
        padding-right: 40px;
        padding-left: 40px;
        
    }
}

.main .page-header {
    margin-top: 0;
}


/*
 * Placeholder dashboard ideas
 */

.placeholders {
    margin-bottom: 30px;
    text-align: center;
}

    .placeholders h4 {
        margin-bottom: 0;
    }

.placeholder {
    margin-bottom: 20px;
}

    .placeholder img {
        display: inline-block;
        border-radius: 50%;
    }


#indicator span {
    display: block;
    height: 25px;
    opacity: 1;
    width: 25px;
}

#indicator #CheckPoint.Red {
    box-shadow: 0 0 10px;
    background-color: red;
}


#indicator #CheckPoint.Green {
    box-shadow: 0 0 10px;
    background-color: green;
}

#indicator #CheckPoint.Yellow {
    box-shadow: 0 0 10px;
    background-color: yellow;
}


.fa-2x {
    font-size: 1.5em !important;
}


.alertify-button-cancel {
    color: #fff;
    background-color: #337ab7 !important;
    border-color: #2e6da4 !important;
}

.alertify-button-ok, .alertify-button-ok:hover, .alertify-button-ok:focus {
    color: #333 !important;
    background-color: #e6e6e6 !important;
    border-color: #adadad !important;
}
.alertify-button-cancel:hover, .alertify-button-cancel:focus {
    color: #fff !important;
    text-decoration: none !important;
}

.alertify-button-ok:hover, .alertify-button-ok:focus{
    text-decoration: none !important;
}

.alertify-log-error {
		color: #a94442 !important;
		background: #f2dede !important;
		border: 1px solid #ebccd1 !important;
	}
	.alertify-log-success {
		color: #fff !important;
		background: #337ab7 !important;
		border: 1px solid #2e6da4 !important;
	}

  /*Ratnesh Changes*/
.h4, h4 {
    font-size: medium !important;
}

.h1, h1 {
    font-size: xx-large !important;
    font-weight: 500 !important;
}

.card-title {
    margin-bottom: auto !important;
}

.card-group > .card {
    margin-bottom: 14px !important;
    flex: 1 0 0% !important;
}

.nav > li > a:focus, .nav > li > a:hover {
    background-color: #eee !important;
    color: #222 !important;
    text-decoration: none !important;
}


html, body {
    height: 100%;
}

/* body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
} */
.mat-toolbar.mat-primary {
    background: #000;
    color: #fff;
    position: fixed;
    z-index: 2;
    top: 0;
    border-bottom: solid 1px #fff;
}
.top-nav {
    display: flex;
    flex-direction: row;
    float: right;
}
ul.top-nav li{
    padding: 7px 15px 0 0;
}
.logo {
    margin-right:10px;
}
/* .container { margin: 1%; } */
.container {
    max-width: 100%;
    padding: 0;
}
.navbar {
    float: left;
    width: 17%;
    background-color: #000;
    color: #fff;
    overflow-y: scroll;
    padding: 0;
    position: fixed;
    top: 0;
    height: 100%;
}
    .navbar ul {
        list-style-type: none;
        padding: 0;
        margin-top: 0;
        width: 100%;
        height: 465px;
    }
.main {
    width: 83%;
    display: inline-block;
    float: right;
    margin-top: 75px;
}



    .navbar ul li {
        padding: 22px 10px 22px 0;
        font-size: 16px;
        border: solid 1px #fff;
        text-align: center;
    }

        .navbar ul li:hover {
            background-color: rgb(42, 40, 40);
            font-size: 18px;
            cursor: pointer;
        }
/*Issue-tracker*/
.title-section {
    width: 100%;
}

.title {
    width: 20%;
    display: inline-block;
}

.buttons {
    width: 55%;
    display: inline-block;
}

.common-button {
    float: right;
    padding: 5px;
    text-align: center;
    color: #000;
    background-color: #f0ffff;
}

.issue-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-left: 5px;
}

    .issue-table td, #issue-table th {
        border: 1px solid #ddd;
        padding: 8px;
        white-space: nowrap;
    }

    .issue-table tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    .issue-table tr:hover {
        background-color: #ddd;
    }

    .issue-table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #f2f2f2;
        color: #000;
    }
form.searchBoxes input {
    width: 175px;
    margin: 0 5px;
}
.common-input {
    padding: 10px;
    text-align: center;
    margin: 5px;
}
.modal-dialog{
    max-width:70% !important;
}
.mat-icon{
    vertical-align: middle;
    height:27px;
}
.logoutButton{
    padding: 5px 14px;
    border-radius: 25px;
    font-size: 16px;
}
.mat-chip-list-wrapper input.mat-input-element, .mat-chip-list-wrapper .mat-standard-chip {
  margin: 4px;
  background-color: #dcdcdc;
}

html, body {
  height: 100%;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
